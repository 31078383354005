<template>
  <VueRecaptcha
  :sitekey="siteKey"
  :load-recaptcha-script="true"
  @verify="handleSuccess"
  @error="handleError"
  ></VueRecaptcha>
</template>

<script setup>
  import { computed } from 'vue';
  import { VueRecaptcha } from 'vue-recaptcha';

    const siteKey = computed(() => {
      return '6LdS9_IfAAAAABsf8ukTzo0eEioEsYaB3I3ZCdws';
    });

    const handleError = () => {
      // Do some validation
    };

    const handleSuccess = (response) => {
      console.log(response);
      // Do some validation
    };
</script>