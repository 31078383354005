<template>
	<form action="https://api.web3forms.com/submit" method="POST" id="form">
      <input type="hidden" name="access_key" value="YOUR_ACCESS_KEY_HERE" />
      <input type="hidden" name="subject" value="New Submission from Web3Forms" />
      <input type="checkbox" name="botcheck" id="" style="display: none;" />

      <div class="mb-6">
        <label for="name" class="block mb-2 pl-2 text-sm text-gray-600 dark:text-gray-400">Full Name</label>
        <input type="text" v-model="name" name="name" id="name" placeholder="John Doe" class="w-full px-5 py-2 placeholder-gray-300 border border-gray-300 rounded-3xl focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
      </div>
      <div class="mb-6">
        <label for="email" class="block mb-2 pl-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
        <input type="email" v-model="email" name="email" id="email" placeholder="you@company.com" class="w-full px-5 py-2 placeholder-gray-300 border border-gray-300 rounded-3xl focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
      </div>
      <!-- <div class="mb-6">
        <label for="phone" class="block text-sm mb-2 pl-4 text-gray-600 dark:text-gray-400">Phone Number</label>
        <input type="text" name="phone" id="phone" placeholder="+1 (555) 1234-567" class="w-full px-5 py-2 placeholder-gray-300 border border-gray-300 rounded-3xl focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500" />
      </div> -->
      <div class="mb-6">
        <label for="message" class="block mb-2 pl-2 text-sm text-gray-600 dark:text-gray-400">Your Message</label>

        <textarea rows="5" v-model="message" name="message" id="message" placeholder="Your Message" class="w-full px-5 py-2 placeholder-gray-300 border border-gray-300 rounded-3xl focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"></textarea>
      </div>
      <div class="mb-6">
        <button type="submit" class="w-full px-5 py-4 text-white bg-indigo-500 rounded-3xl focus:bg-indigo-600 focus:outline-none">Send Message</button>
      </div>
      <p class="text-base text-center text-gray-400" id="result"></p>
      <re-captcha></re-captcha>
    </form>
</template>
<script>
	import ReCaptcha from "../components/ReCaptcha";
	export default {
		name: "App",
		components: { ReCaptcha },
		data() {
			return {
				name: "",
				email: "",
				message: "",
			};
		},
		computed: {
			formValid() {
				const { name, email, message } = this;
				return (
					name.length > 0 &&
					/(.+)@(.+){2,}.(.+){2,}/.test(email) &&
					message.length > 0
					);
			},
		},
		methods: {
			onReset() {
				this.name = "";
				this.email = "";
				this.message = "";
			},
			submit() {
				if (!this.formValid) {
					return;
				}
				if (!localStorage.getItem("messages")) {
					localStorage.setItem("messages", JSON.stringify([]));
				}
				const messages = JSON.parse(localStorage.getItem("messages"));
				const { name, email, message } = this;
				messages.push({
					name,
					email,
					message,
				});
				localStorage.setItem("messages", JSON.stringify(messages));
			},
		},
	};
</script>