<template>
  <div class="page-wrapper">
    <div class="home">
      <main>
        <div class="banner">
          <div class="banner-text">
            <h1 ref="greeting">Hi I'm</h1>
            <h2>Sabin.</h2>
            <div class="goto-contact" @click="gotoContact">Contact Me</div>
          </div>
        </div>
      </main>
    </div>
    <div id="contact" class="contact">
      <div class="contact-wrapper text-left lg:w-1/3 md:w-1/2 ml-auto">
        <contact-form></contact-form>
      </div>
    </div>
  </div>
</template>

<script setup>
  import ContactForm from "../components/ContactForm";
  const gotoContact = () => {
      document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  }
</script>
<style>
  body {margin: 0; padding: 0;}
</style>
<style scoped>
main {
  display: flex;
  flex-flow: row wrap-reverse;
  justify-content: space-between;
  width: 100%;
}
h1 {
  margin: 0;
  font-weight: 300;
  font-size: clamp(32px, 4vw, 52px);
}
h2 {
  margin: 0;
  font-size: clamp(62px, 8vw, 140px);
  font-weight: 900;
}
h3 {
  text-align: center;
  font-size: clamp(20px, 2.8vw, 44px);
  margin: 0;
  font-weight: 700;
}
img {
  border: 10px solid white;
  border-radius: 50%;
  width: clamp(200px, 20vw, 20vw);
}


@media (max-width: 700px) {
  main {
    justify-content: center;
    width: 100%;
  }
  img {
    margin-bottom: 2em;
  }
}
.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home::before {
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  background-image: url("../assets/bg.jpg");
  background-size: cover;
  filter:brightness(0.1);
  z-index: 0;
}
.banner {
  width: 60vw; color: #fff; position: relative; z-index: 9;
}
.goto-contact {background: #fff; padding: 0.8em 1.5em; border-radius: 50px; display: inline-block; color: #000; font-size: clamp(18px, 4vw, 28px); cursor: pointer; margin-top: 1em;}
.contact-wrapper {padding: 10vh;}
</style>